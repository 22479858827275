/* ExpandingMenu.css rect-lib */

.custom-expanding-menu .ui.accordion .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.custom-expanding-menu .ui.accordion .icon {
  margin-left: auto !important;
  order: 2;
}

.custom-expanding-menu .ui.accordion .title-content {
  order: 1;
  flex-grow: 1;
  text-align: center;
}

.custom-expanding-menu .ui.accordion .title:hover .icon {
  color: rgba(0, 0, 0, 0.87) !important;
}

.custom-expanding-menu .ui.accordion .title {
  color: rgba(0, 0, 0, 0.87) !important;
}

.custom-expanding-menu .ui.accordion .title:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}