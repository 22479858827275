@mixin inline-label {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  min-width: 100%;
  display: flex;
}

@mixin checkbox-light-blue {
  & input:checked {
    & ~ label:before {
      background: var(--primary-theme-color);
      border-color: var(--primary-theme-color);
    }

    & ~ .box:after,
    & ~ label:after {
      color: white;
      transform: scale(0.7);
    }
  }
}

.DropdownOptions {
  .ui.checkbox {
    @include checkbox-light-blue;
  }

  &.--inline-label .ui.multiple.dropdown > span:nth-of-type(1) {
    @include inline-label;
  }

  .ui.multiple.dropdown {
    > .label {
      background: var(--primary-theme-chat-half-light);
      box-shadow: none;
    }

    > span:nth-of-type(1) {
      > .label {
        min-width: max-content;
        user-select: none;
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        font-size: 1em;
        padding: 0.35714286em 0.78571429em;
        margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
        background: var(--primary-theme-chat-half-light);
        box-shadow: none;
      }
    }
  }

  .ui.dropdown .menu {
    overflow-y: hidden;
    > div:nth-of-type(2) {
      overflow-y: auto;

      @media only screen and (min-width: 992px) {
        max-height: 16.02857143rem;
      }

      @media only screen and (min-width: 768px) {
        max-height: 10.68571429rem;
      }

      .active.item {
        background: 0 0;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.95);
        box-shadow: none;
        z-index: 12;
      }
    }

    > .item {
      position: relative;
      cursor: pointer;
      display: block;
      border: none;
      height: auto;
      text-align: left;
      border-top: none;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.78571429rem 1.14285714rem !important;
      font-size: 1rem;
      text-transform: none;
      font-weight: 400;
      box-shadow: none;
    }
  }

  .ui.selection.dropdown .menu > div:nth-of-type(2) {
    > .item {
      border-top: 1px solid #fafafa;
      padding: 0.78571429rem 1.14285714rem !important;
      white-space: normal;
      word-wrap: normal;
    }
  }
}

.inline-label .ui.dropdown.selection > .text,
.inline-label.ui.dropdown.selection > .text {
  @include inline-label;
}

.ui.checkbox.blue {
  & input:checked {
    & ~ label:before {
      background: #0072bc;
      border-color: #0072bc;
    }

    & ~ .box:after,
    & ~ label:after {
      color: white;
      transform: scale(0.7);
    }
  }
}

.ui.checkbox.black {
  & input:checked {
    & ~ label:before {
      background: #000000;
      border-color: #000000;
    }

    & ~ .box:after,
    & ~ label:after {
      color: white;
      transform: scale(0.7);
    }
  }
}

.ui.checkbox.light-blue {
  @include checkbox-light-blue;
}
