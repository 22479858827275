/* CardBillingDeposit.css react-lib */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {
    .rps-billing-deposit-addlist .rps-form-group {
      display: flex;
      justify-content: space-between;
    }

    .rps-billing-deposit-filterbox .rps-form-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
    }

    .rps-billing-deposit-filterbox .rps-drop-down {
      min-width: 35%;
    }
}