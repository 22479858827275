/* CardConsultDetail.css rect-lib */
.rps-date-info {
  position: absolute;
  padding: 10px;
  background-color: white;
  bottom: -60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: nowrap;
}

@media only screen and (max-width: 1024px) {
  .rps-date-info {
    transform: translate(-50%, -30%);
    font-size: 75%;
  }
}